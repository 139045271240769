import React from "react";
import { TextInput } from "react-admin";
import { Grid, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { doFlex } from "./styles";
import { useContacts } from "./ContactContext";
import config from "../../config";

const apiUrl = config.apiUrl;
const ContactEmailSection = ({ index, contactPersonIndex, email = "" }) => {
    const { deleteEmail, updateEmailField } = useContacts();
    const emailId = email.id;

    const validateEmail = (value, allValues) => {
        const contact = allValues.contacts[contactPersonIndex];
        if (contact.emails.length > 1 && !value) {
            return "Empty fields could not be submitted";
        }
        return undefined;
    };
    const handleEmailChange = (event) => {
        const updatedEmail = event.target.value;
        updateEmailField(contactPersonIndex, index, updatedEmail);
    };
    const handleDeleteEmail = async (contactPersonIndex, index) => {
        deleteEmail(contactPersonIndex, index);
        try {
            const response = await fetch(`${apiUrl}?section=kundenlistes&action=deleteEmail&id=${emailId}`, {
                method: "POST",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const result = await response.json();
        } catch (err) {
            console.error("Email not deleted", err);
        }
    };

    return (
        <>
            <TextInput source={`contacts[${contactPersonIndex}].emails[${index}].id`} label="ID" readOnly fullWidth size="large" helperText={false} sx={{ display: "none" }} />
            <Grid item xs={12} md={12} flexGrow={1} style={doFlex} marginTop={"15px"}>
                <TextInput source={`contacts[${contactPersonIndex}].emails[${index}].email`} label={`E-Mail ${index + 1}`} type="email" defaultValue={email.email ? email.email : ""} fullWidth validate={validateEmail} size="large" onChange={handleEmailChange} helperText={false} />
            </Grid>
            <Grid item xs={12} md={1} style={doFlex} marginTop={"15px"}>
                {/* {index > 0 && ( */}
                    <IconButton onClick={() => handleDeleteEmail(contactPersonIndex, index)} color="error" size="small">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                {/* )} */}
            </Grid>
        </>
    );
};

export default ContactEmailSection;
