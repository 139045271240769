import React, { useEffect, useState } from "react";
import { Create, SimpleForm, TextInput, DateInput, required, ReferenceInput, AutocompleteInput, Edit, useRecordContext, NumberInput } from "react-admin";
import { Card, CardContent, Grid, Typography, Paper, Snackbar, Alert, IconButton, useTheme } from "@mui/material";
import ContactPersonSection from "./ContactPersonSection.js";
import { useContacts } from "./ContactContext";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const CustomerForm = ({ isEditing, props, title, actions, toolbar }) => {
    const theme = useTheme();
    const { contacts, copySuccess, isSnackbarOpen, setSnackbarOpen } = useContacts();
    const [clipboardData, setClipboardData] = useState({
        name: "",
        street: "",
        postalCode: "",
        city: "",
    });

    const handleClipboardDataChange = (e, identifier) => {
        setClipboardData((prevData) => ({
            ...prevData,
            [identifier]: e.target.value
        }));
    };

    const copyAddressToClipboard = () => {
        const textToCopy = `${clipboardData.name}\n${clipboardData.street}\n${clipboardData.postalCode} ${clipboardData.city}`;
        navigator.clipboard.writeText(textToCopy).then(
            () => setSnackbarOpen(true),
            (err) => console.error("Failed to copy: ", err)
        );
    };

    const copyContactToClipboard = (contact) => {
        const phoneNumbers = contact.phoneNumbers
            ?.filter(phone => phone.phoneNumber)
            .map(phone => `${phone.countryCode ?? ''}${phone.phoneNumber}`)
            .join('\n') || '';

        const emails = contact.emails
            ?.filter(emailObj => emailObj.email)
            .map(emailObj => emailObj.email)
            .join('\n') || '';

        const nameParts = `${contact.salutation ?? ''} ${contact.firstName ?? ''} ${contact.lastName ?? ''}`.trim();
        const textToCopy = `${nameParts}\n\n${phoneNumbers ? `\n${phoneNumbers}\n\n` : ''}${emails ? `\n${emails}` : ''}`;
        navigator.clipboard.writeText(textToCopy).then(
            () => setSnackbarOpen(true),
            (err) => console.error("Failed to copy: ", err)
        );
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (copySuccess) {
        prompt(copySuccess);
    }
    if (isEditing) {
        return (
            <>
                <Edit title={title} actions={actions} {...props}>
                    <EditForm toolbar={toolbar}></EditForm>
                </Edit>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} sx={{ marginTop: "50px" }}>
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
                        Copied!
                    </Alert>
                </Snackbar>
            </>
        );
    } else {
        return (
            <>
                <Create title={title} actions={actions} {...props}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} sm={12}>
                            <SimpleForm toolbar={toolbar}>
                                <Card
                                    variant="plain"
                                    sx={{
                                        [theme.breakpoints.down("sm")]: {
                                            marginBottom: "0px",
                                            paddingBottom: "0px",
                                        },
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6" sx={{ mb: 3 }}>
                                            Kundeninformationen
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={9}>
                                                <TextInput source="company" label="Unternehmen" defaultValue="" validate={required()} fullWidth size="large" onChange={(e) => handleClipboardDataChange(e, "name")} helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextInput source="id" label="ID" readOnly fullWidth size="large" helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextInput source="street" label="Straßenname und Nr." size="large" validate={required()} fullWidth defaultValue="" onChange={(e) => handleClipboardDataChange(e, "street")} helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextInput source="postalCode" label="PLZ" defaultValue="" size="large" validate={required()} onChange={(e) => handleClipboardDataChange(e, "postalCode")} fullWidth helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextInput source="city" defaultValue="" label="Stadt" validate={required()} onChange={(e) => handleClipboardDataChange(e, "city")} fullWidth size="large" helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextInput source="company_website" defaultValue="" label="Unternehmen Webseite" onChange={(e) => handleClipboardDataChange(e, "company_website")} fullWidth size="large" helperText={false} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput source="specialRequests" label="Spezielle Kundenwünsche" multiline rows={3} size="large" defaultValue="" helperText={false} fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput source="notes" defaultValue="" label="Anmerkung" multiline rows={3} size="large" fullWidth helperText={false} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput source="constructionSite" defaultValue="" label="Baustelle" size="large" helperText={false} fullWidth />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <ReferenceInput
                                                    source="regionId"
                                                    reference="regions"
                                                    label="Region"
                                                    fullWidth
                                                    size="large"
                                                    // validate={required()}
                                                >
                                                    <AutocompleteInput optionText="name" label="Region" validate={required()} helperText={false} />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <ReferenceInput source="account_manager_id" label="Kundenbetreuer" reference="users" >
                                                    <AutocompleteInput optionText="name" label="Kundenbetreuer" validate={required()} helperText={false} />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={12} md={4} marginTop={"0px"}>
                                                <DateInput source="customerSince" label="Kunde seit" defaultValue="" fullWidth size="large" validate={required()} helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={6} marginTop={"0px"}>
                                                <NumberInput source="units_under_management" label="Anzahl Einheiten" size="large" helperText={false} fullWidth min={1} max={1000000}/>
                                            </Grid>
                                            <Grid item xs={12} md={6} marginTop={"0px"}>
                                                <TextInput source="employee_count" defaultValue="" label="Anzahl Mitarbeiter" size="large" helperText={false} fullWidth />
                                            </Grid>
                                        </Grid>
                                        {contacts.map((contact, index) => (
                                            <ContactPersonSection key={index} index={index} contact={contact} />
                                        ))}
                                    </CardContent>
                                </Card>
                            </SimpleForm>
                        </Grid>
                        <Grid item xs={12} md={4} sm={12}>                    
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7} sm={12}>
                                    <Paper elevation={0} sx={{
                                        backgroundColor: "#EFF4FA",
                                        borderRadius: "6px",
                                        p: 2,
                                        marginTop: "90px"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                            <Typography variant="p" sx={{ fontSize: "1rem", fontWeight: "600", padding: "4px 8px", }}>
                                                Kontaktdaten
                                            </Typography>
                                            <IconButton 
                                                size="small" 
                                                sx={{ color: "#3d3d3d" }} 
                                                onClick={copyAddressToClipboard}
                                            >
                                                <ContentCopyRoundedIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                        <Typography variant="body1" sx={{ mt: 2, ml: 1, fontSize: "0.9rem", fontWeight: "500" }}>
                                            {clipboardData.name}<br />
                                            {clipboardData.street}<br />
                                            {clipboardData.postalCode} {clipboardData.city}
                                        </Typography>
                                    </Paper>
                                </Grid>                                   
                                {contacts.map((contact, index) => (
                                    contact.firstName || contact.lastName ? (
                                        <Grid item xs={12} md={7} sm={12} key={index}>
                                            <Paper elevation={0} sx={{
                                                backgroundColor: "#EFF4FA",
                                                borderRadius: "6px",
                                                p: 2,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}>
                                                    <Typography variant="p" sx={{ 
                                                        fontSize: "1rem", 
                                                        fontWeight: "600",
                                                        // backgroundColor: "#D1E3FA",
                                                        padding: "4px 8px",
                                                        // borderRadius: "4px"
                                                    }}>
                                                        {contact.salutation} {contact.firstName} {contact.lastName}
                                                    </Typography>
                                                    <IconButton 
                                                        size="small" 
                                                        sx={{ color: "#3d3d3d" }} 
                                                        onClick={() => copyContactToClipboard(contact)}
                                                    >
                                                        <ContentCopyRoundedIcon fontSize="small" />
                                                    </IconButton>
                                                </div>

                                                {contact.phoneNumbers.some(phone => phone.phoneNumber) && (
                                                    <div style={{ marginTop: "16px" }}>
                                                        {contact.phoneNumbers.map((phone, phoneIndex) => (
                                                            phone.phoneNumber && (
                                                                <Typography key={phoneIndex} variant="body2" sx={{ ml: 1, mb: 1 }}>
                                                                    <a href={`tel:+${phone.countryCode}${phone.phoneNumber}`} style={{ textDecoration: "none", color: "inherit" }}>
                                                                        +{phone.countryCode} {phone.phoneNumber}
                                                                    </a>
                                                                </Typography>
                                                            )
                                                        ))}
                                                    </div>
                                                )}
                                                 {contact.emails.some(email => ((email && email.email) || email)) && (
                                                    <div style={{ marginTop: "16px" }}>
                                                        {contact.emails.map((email, emailIndex) => {
                                                            const emailValue = email && email.email ? email.email : email;
                                                            if (emailValue) {
                                                                return (
                                                                    <Typography key={emailIndex} variant="body2" sx={{ ml: 1, mb: 1,  }}>
                                                                        <a href={`mailto:${emailValue}`} style={{ textDecoration: "none", color: "inherit" }}>
                                                                            {emailValue}
                                                                        </a>
                                                                    </Typography>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                )}
                                            </Paper>
                                        </Grid>
                                    ) : null
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Create>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} sx={{ marginTop: "50px" }}>
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
                        Copied!
                    </Alert>
                </Snackbar>
            </>
        );
    }
};

const EditForm = ({ toolbar }) => {
    const theme = useTheme(); 
    const contactRecord = useRecordContext();
    const { contacts, copySuccess, setSnackbarOpen, setContacts } = useContacts();
    useEffect(() => {
        if (contactRecord.contacts && contactRecord.contacts.length >= 1) {
            setContacts(contactRecord.contacts);
        }
    }, [contactRecord.contacts, setContacts]);
    const [clipboardData, setClipboardData] = useState({
        name: contactRecord.company || "",
        street: contactRecord.street || "",
        postalCode: contactRecord.postalCode || "",
        city: contactRecord.city || ""
    });

    const handleClipboardDataChange = (e, identifier) => {
        setClipboardData((prevData) => ({
            ...prevData,
            [identifier]: e.target.value
        }));
    };

    const copyAddressToClipboard = () => {
        const textToCopy = `${clipboardData.name}\n${clipboardData.street}\n${clipboardData.postalCode} ${clipboardData.city}`;
        navigator.clipboard.writeText(textToCopy).then(
            () => setSnackbarOpen(true),
            (err) => console.error("Failed to copy: ", err)
        );
    };
    const copyContactToClipboard = (contact) => {
        const phoneNumbers = contact.phoneNumbers
            ?.filter(phone => phone.phoneNumber)
            .map(phone => `${phone.countryCode ?? ''}${phone.phoneNumber}`)
            .join('\n') || '';

        const emails = contact.emails
            ?.filter(emailObj => emailObj.email)
            .map(emailObj => emailObj.email)
            .join('\n') || '';

        const nameParts = `${contact.salutation ?? ''} ${contact.firstName ?? ''} ${contact.lastName ?? ''}`.trim();
        const textToCopy = `${nameParts}\n\n${phoneNumbers ? `\n${phoneNumbers}\n\n` : ''}${emails ? `\n${emails}` : ''}`;
        navigator.clipboard.writeText(textToCopy).then(
            () => setSnackbarOpen(true),
            (err) => console.error("Failed to copy: ", err)
        );
    };

    // const handleSnackbarClose = () => {
    //     setSnackbarOpen(false);
    // };

    if (copySuccess) {
        prompt(copySuccess);
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} sm={12}>
                <SimpleForm toolbar={toolbar}>
                    <Card
                        variant="plain"
                        sx={{
                            [theme.breakpoints.down("sm")]: {
                                marginBottom: "0px",
                                paddingBottom: "0px",
                            },
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" sx={{ mb: 3 }}>
                                Kundeninformationen
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <TextInput source="company" label="Unternehmen" validate={required()} fullWidth size="large" onChange={(e) => handleClipboardDataChange(e, "name")} helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextInput source="id" label="ID" readOnly fullWidth size="large" helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput source="street" label="Straßenname und Nr." size="large" fullWidth validate={required()} onChange={(e) => handleClipboardDataChange(e, "street")} helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextInput source="postalCode" label="PLZ" validate={required()} size="large" onChange={(e) => handleClipboardDataChange(e, "postalCode")} fullWidth helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextInput source="city" validate={required()} label="Stadt" onChange={(e) => handleClipboardDataChange(e, "city")} fullWidth size="large" helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextInput source="company_website" defaultValue="" label="Unternehmen Webseite" onChange={(e) => handleClipboardDataChange(e, "company_website")} fullWidth size="large" helperText={false} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput source="specialRequests" label="Spezielle Kundenwünsche" multiline rows={3} size="large" helperText={false} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput source="notes" label="Anmerkung" multiline rows={3} size="large" fullWidth helperText={false} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput source="constructionSite" defaultValue="" label="Baustelle" size="large" helperText={false} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ReferenceInput
                                        source="regionId"
                                        reference="regions"
                                        label="Region"
                                        fullWidth
                                        helperText={false}
                                        size="large"
                                        // validate={required()}
                                    >
                                        <AutocompleteInput optionText="name" label="Region" validate={required()} helperText={false} />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ReferenceInput source="account_manager_id" label="Kundenbetreuer" reference="users">
                                        <AutocompleteInput optionText="name" validate={required()} label="Kundenbetreuer" helperText={false}/>
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} md={4} marginTop={"0px"}>
                                    <DateInput source="customerSince" label="Kunde seit" fullWidth validate={required()} size="large" helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={6} marginTop={"0px"}>
                                    <NumberInput source="units_under_management" label="Anzahl Einheiten" size="large" helperText={false} fullWidth min={1} max={1000000}/>
                                </Grid>
                                <Grid item xs={12} md={6} marginTop={"0px"}>
                                    <TextInput source="employee_count" defaultValue="" label="Anzahl Mitarbeiter" size="large" helperText={false} fullWidth />
                                </Grid>
                            </Grid>
                            {contacts.map((contact, index) => (
                                <ContactPersonSection key={index} index={index} contact={contact} />
                            ))}
                        </CardContent>
                    </Card>
                </SimpleForm>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>                    
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7} sm={12}>
                        <Paper elevation={0} sx={{
                            backgroundColor: "#EFF4FA",
                            borderRadius: "6px",
                            p: 2,
                            marginTop: "90px"
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                                <Typography variant="p" sx={{ fontSize: "1rem", fontWeight: "600", padding: "4px 8px", }}>
                                    Kontaktdaten
                                </Typography>
                                <IconButton 
                                    size="small" 
                                    sx={{ color: "#3d3d3d" }} 
                                    onClick={copyAddressToClipboard}
                                >
                                    <ContentCopyRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                            <Typography variant="body1" sx={{ mt: 2, ml: 1, fontSize: "0.9rem", fontWeight: "500" }}>
                                {clipboardData.name}<br />
                                {clipboardData.street}<br />
                                {clipboardData.postalCode} {clipboardData.city}
                            </Typography>
                        </Paper>
                    </Grid>                                   
                    {contacts.map((contact, index) => (
                        contact.firstName || contact.lastName ? (
                            <Grid item xs={12} md={7} sm={12} key={index}>
                                <Paper elevation={0} sx={{
                                    backgroundColor: "#EFF4FA",
                                    borderRadius: "6px",
                                    p: 2,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Typography variant="p" sx={{ 
                                            fontSize: "1rem", 
                                            fontWeight: "600",
                                            // backgroundColor: "#D1E3FA",
                                            padding: "4px 8px",
                                            // borderRadius: "4px"
                                        }}>
                                            {contact.salutation} {contact.firstName} {contact.lastName}
                                        </Typography>
                                        <IconButton 
                                            size="small" 
                                            sx={{ color: "#3d3d3d" }} 
                                            onClick={() => copyContactToClipboard(contact)}
                                        >
                                            <ContentCopyRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    {contact.phoneNumbers.some(phone => phone.phoneNumber) && (
                                        <div style={{ marginTop: "16px" }}>
                                            {contact.phoneNumbers.map((phone, phoneIndex) => (
                                                phone.phoneNumber && (
                                                    <Typography key={phoneIndex} variant="body2" sx={{ ml: 1, mb: 1 }}>
                                                        <a href={`tel:+${phone.countryCode}${phone.phoneNumber}`} style={{ textDecoration: "none", color: "inherit" }}>
                                                            +{phone.countryCode} {phone.phoneNumber}
                                                        </a>
                                                    </Typography>
                                                )
                                            ))}
                                        </div>
                                    )}
                                    {contact.emails.some(email => ((email && email.email) || email)) && (
                                        <div style={{ marginTop: "16px" }}>
                                            {contact.emails.map((email, emailIndex) => {
                                                const emailValue = email && email.email ? email.email : email;
                                                if (emailValue) {
                                                    return (
                                                        <Typography key={emailIndex} variant="body2" sx={{ ml: 1, mb: 1,  }}>
                                                            <a href={`mailto:${emailValue}`} style={{ textDecoration: "none", color: "inherit" }}>
                                                                {emailValue}
                                                            </a>
                                                        </Typography>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                        ) : null
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default CustomerForm;
