import React, { createContext, useContext, useState } from "react";

const ContactContext = createContext();

export const useContacts = () => {
    return useContext(ContactContext);
};

export const ContactProvider = ({ children }) => {
    const [contacts, setContacts] = useState([
        {
            emails: [""],
            phoneNumbers: [
                {
                    label: "",
                    countryCode: "49",
                    phoneNumber: "",
                    flagUrl: "https://flagcdn.com/w20/de.png",
                    flagName: "Germany",
                    countryCodeLabel: "DE",
                },
            ],
            salutation: "",
            firstName: "",
            lastName: "",
        },
    ]);
    const [copySuccess, setCopySuccess] = useState("");
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const addContact = () => {
        setContacts([
            ...contacts,
            {
                emails: [""],
                phoneNumbers: [
                    {
                        label: "",
                        countryCode: "49",
                        phoneNumber: "",
                        flagUrl: "https://flagcdn.com/w20/de.png",
                        flagName: "Germany",
                        countryCodeLabel: "DE",
                    },
                ],
                salutation: "",
                firstName: "",
                lastName: "",
            },
        ]);
    };
    const addContactNumber = (contactIndex) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          phoneNumbers: [
                              ...contact.phoneNumbers,
                              {
                                  label: "",
                                  countryCode: "49",
                                  phoneNumber: "",
                                  flagUrl: "https://flagcdn.com/w20/de.png",
                                  flagName: "Germany",
                                  countryCodeLabel: "DE",
                              },
                          ],
                      }
                    : contact
            )
        );
    };
    const addContactEmail = (contactIndex) => {
        setContacts((prevContacts) => {
            return prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          emails: [...contact.emails, ""],
                      }
                    : contact
            );
        });
    };
    const updateContactNumberField = (contactIndex, phoneNumberIndex, field, value) => {
        setContacts((prevContacts) => {
            // Create a copy of contacts array
            const updatedContacts = prevContacts.map((contact, i) => {
                if (i === contactIndex) {
                    // Create a copy of the contact object
                    const updatedContact = { ...contact };
                    
                    // Update phoneNumbers immutably
                    updatedContact.phoneNumbers = updatedContact.phoneNumbers.map((phone, j) => {
                        if (j === phoneNumberIndex) {
                            return { ...phone, [field]: value }; // Update the specific field
                        }
                        return phone;
                    });

                    return updatedContact;
                }
                return contact;
            });

            return updatedContacts; // Return the updated array
        });
    };
    const updateEmailField = (contactIndex, emailIndex, value) => {
        setContacts((prevContacts) => {
            // Create a copy of the contacts array
            const updatedContacts = prevContacts.map((contact, i) => {
                if (i === contactIndex) {
                    // Create a copy of the contact object
                    const updatedContact = { ...contact };
    
                    // Update the specific email immutably
                    updatedContact.emails = updatedContact.emails.map((email, j) => {
                        if (j === emailIndex) {
                            return value; // Replace the email string with the updated value
                        }
                        return email;
                    });
    
                    return updatedContact;
                }
                return contact;
            });
    
            return updatedContacts; // Return the updated array
        });
    };
    
    const updateContactField = (index, field, value) => {
        setContacts((prevContacts) => prevContacts.map((contact, i) => (i === index ? { ...contact, [field]: value } : contact)));
    };
    const deleteContact = (index) => {
        if (contacts.length > 1) {
            setContacts((prevContacts) =>
                prevContacts.filter((_, i) => i !== index)
            );
        }
    };

    const deletePhoneNumber = (contactIndex, phoneNumberIndex) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          phoneNumbers: contact.phoneNumbers.filter((_, i) => i !== phoneNumberIndex),
                      }
                    : contact
            )
        );
    };
    

    const deleteEmail = (contactIndex, emailIndex) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          emails: contact.emails.filter((_, i) => i !== emailIndex),
                      }
                    : contact
            )
        );
    };
    

    return (
        <ContactContext.Provider
            value={{
                contacts,
                setContacts,
                addContact,
                addContactNumber,
                addContactEmail,
                updateContactField,
                updateEmailField,
                updateContactNumberField,
                deleteContact,
                deletePhoneNumber,
                deleteEmail,
                copySuccess,
                setCopySuccess,
                isSnackbarOpen,
                setSnackbarOpen,
            }}
        >
            {children}
        </ContactContext.Provider>
    );
};
