import React, { useState } from "react";
import { Grid, Box, Typography, useMediaQuery, IconButton, Alert } from "@mui/material";
import { StyledButton } from "./StyledButton";
import ContactNumberSection from "./ContactNumberSection";
import ContactEmailSection from "./ContactEmailSection";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { doFlex, titleChoices } from "./styles";
import { SelectInput, TextInput } from "react-admin";
import { useContacts } from "./ContactContext";
import config from "../../config";

const apiUrl = config.apiUrl;

const ContactPersonSection = ({ index, contact }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [error, setError] = useState(null);

    const { contacts, addContact, addContactNumber, deleteContact, addContactEmail, updateContactField } = useContacts();
    const handleAddContact = () => {
        addContact();
    };

    const handleAddContactNumber = (contactNumIndex) => {
        if (contact.phoneNumbers.length >= 3) {
            // setError(
            //   "Sie können pro Kontaktperson maximal 3 Telefonnummern hinzufügen."
            // );
            setError("You can only add up to 3 phone numbers per contact person.");
            setTimeout(() => setError(null), 5000);
            return;
        }
        addContactNumber(contactNumIndex);
    };

    const handleAddContactEmail = (emailIndex) => {
        if (contact.emails.length >= 3) {
            setError("You can only add up to 3 email addresses per contact person.");
            setTimeout(() => setError(null), 5000);
            return;
        }
        addContactEmail(emailIndex);
    };
    const handleContactPersonDataChange = (e, field) => {
        updateContactField(index, field, e.target.value);
    };
    const handleDeleteContact = async (contactPersonId, index) => {
        deleteContact(index);
        try {
            const response = await fetch(`${apiUrl}?section=kundenlistes&action=deleteContactPerson&id=${contactPersonId}`, {
                method: "POST",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const result = await response.json();
            console.log("Contact person deleted successfully", result);
        } catch (err) {
            console.error("Contact person not deleted", err);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Ansprechpartner {index > 0 ? index + 1 : ""}
                </Typography>

                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    {/* {index > 0 && ( */}
                        <IconButton onClick={() => handleDeleteContact(contact.id, index)} color="error" sx={{ mb: 2 }} size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    {/* )} */}
                    <StyledButton onClick={handleAddContact} color="primary" sx={{ mb: 2 }}>
                        <AddIcon fontSize="small" />
                        {!isMobile && " Ansprechpartner hinzufügen"}
                    </StyledButton>
                </div>
            </Box>
            <Grid container spacing={2} style={doFlex}>
                <Grid item xs={12} md={4}>
                    <SelectInput source={`contacts[${index}].salutation`} choices={titleChoices} onChange={(e) => handleContactPersonDataChange(e, "salutation")} label="Anrede" fullWidth style={doFlex} size="large" helperText={false} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput source={`contacts[${index}].firstName`} onChange={(e) => handleContactPersonDataChange(e, "firstName")} defaultValue={contact.firstName} label="Vorname" fullWidth style={doFlex} size="large" helperText={false} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput source={`contacts[${index}].lastName`} onChange={(e) => handleContactPersonDataChange(e, "lastName")} defaultValue={contact.lastName} label="Nachname" fullWidth size="large" style={doFlex} helperText={false} />
                </Grid>
                {contact.phoneNumbers.map((phoneNumber, numIndex) => (
                    <ContactNumberSection key={numIndex} index={numIndex} contactPersonIndex={index} phoneNumber={phoneNumber} />
                ))}
                <StyledButton label="Telefonnummer hinzufügen" onClick={() => handleAddContactNumber(index)} color="primary" sx={{ mb: 2, width: "100%", mt: 2 }} lg={12} md={12} sm={12}>
                    <AddIcon fontSize="small" /> Telefonnummer hinzufügen
                </StyledButton>
                {contact.emails.map((emailRec, emailIndex) => {
                    return <ContactEmailSection key={emailIndex} contactPersonIndex={index} email={emailRec} index={emailIndex} />;
                })}
                <StyledButton label="E-mail-Adresse hinzufügen" onClick={() => handleAddContactEmail(index)} color="primary" sx={{ mb: 2, width: "100%", mt: 3 }}>
                    <AddIcon fontSize="small" /> E-mail-Adresse hinzufügen
                </StyledButton>
                {/* Sticky Error Alert */}
                {error && (
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            zIndex: 1000,
                            width: "auto",
                            maxWidth: "90%",
                        }}
                    >
                        <Alert
                            severity="error"
                            onClose={() => setError(null)}
                            sx={{
                                minWidth: "300px",
                            }}
                        >
                            {error}
                        </Alert>
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

export default ContactPersonSection;
